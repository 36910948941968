@font-face {
    font-family: "Paper Mario Dialog Redesigned";
    src: url("pmdialog2.woff2") format("woff2");
}

html {
    --dark: #313131;
    --light: #d6d6ce;

    font-size: 21px;
    font-size: clamp(21px, 1.5vw, 32px);
    font-family: "Paper Mario Dialog Redesigned", sans-serif;
    font-weight: 700;
    line-height: 1.2;

    color: var(--light);
    --text-outline: var(--dark);

    background-color: #002c02;
    background-image: url(bg/mrn-toad-town.png);
    background-size: cover;
    background-repeat: no-repeat;

    overflow: hidden;
}

body {
    height: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;

    background-image: url(bg/mrn-clouds.png);
    background-repeat: repeat-x;
    background-size: contain;
    animation: clouds linear 30s infinite normal;

    /* centre children */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes clouds {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 100vw 0;
    }
}

* {
    box-sizing: border-box;

    /* https://owumaro.github.io/text-stroke-generator/ */
    text-shadow: var(--text-outline) 3px 0px 0px, var(--text-outline) 2.83487px 0.981584px 0px, var(--text-outline) 2.35766px 1.85511px 0px, var(--text-outline) 1.62091px 2.52441px 0px, var(--text-outline) 0.705713px 2.91581px 0px, var(--text-outline) -0.287171px 2.98622px 0px, var(--text-outline) -1.24844px 2.72789px 0px, var(--text-outline) -2.07227px 2.16926px 0px, var(--text-outline) -2.66798px 1.37182px 0px, var(--text-outline) -2.96998px 0.42336px 0px, var(--text-outline) -2.94502px -0.571704px 0px, var(--text-outline) -2.59586px -1.50383px 0px, var(--text-outline) -1.96093px -2.27041px 0px, var(--text-outline) -1.11013px -2.78704px 0px, var(--text-outline) -0.137119px -2.99686px 0px, var(--text-outline) 0.850987px -2.87677px 0px, var(--text-outline) 1.74541px -2.43999px 0px, var(--text-outline) 2.44769px -1.73459px 0px, var(--text-outline) 2.88051px -0.838247px 0px;
}

p {
    margin: 0;
}

a:any-link:not(.tab) {
    color: #3796ff;
    --text-outline: #d3e5f9;
    text-decoration: none;
}

button, .tab {
    all: unset;

    border-radius: 100em;
    padding: 0 .4em .2em .4em;

    filter: brightness(1.0);
    will-change: filter, box-shadow;
    transition: filter 100ms ease-out, box-shadow 100ms ease-out;

    border-top: 4px solid;
    border-left: 2px solid;
    border-bottom: 4px solid;
    border-right: 2px solid;

    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

    user-select: none;
    text-align: center;

    color: var(--light);
}

button.red, .tab.red {
    border-top-color: #f04e54;
    border-left-color: #f04e54;
    background: #b4313e;
    border-bottom-color: #7f1729;
    border-right-color: #7f1729;
}

button.yellow, .tab.yellow {
    border-top-color: #f0c74e;
    border-left-color: #f0c74e;
    background: #b48b31;
    border-bottom-color: #7f5617;
    border-right-color: #7f5617;
}

button.blurple, .tab.blurple {
    border-top-color: #98a9e2;
    border-left-color: #98a9e2;
    background: #7289da;
    border-bottom-color: #4E5D94;
    border-right-color: #4E5D94;
}

button.github, .tab.github {
    border-top-color: #554e4e;
    border-left-color: #554e4e;
    background: #322f2f;
    border-bottom-color: #171515;
    border-right-color: #171515;
}

button.teal, .tab.teal {
    border-top-color: #22eec9;
    border-left-color: #22eec9;
    background: #14a98e;
    border-bottom-color: #076d5a;
    border-right-color: #076d5a;
}

button.green, .tab.green {
    border-top-color: #68ff51;
    border-left-color: #68ff51;
    background: #47b836;
    border-bottom-color: #2a791e;
    border-right-color: #2a791e;
}

button.blue, .tab.blue {
    border-top-color: #51eeff;
    border-left-color: #51eeff;
    background: #00b3ff;
    border-bottom-color: #0050c9;
    border-right-color: #0050c9;
}

button.orange, .tab.orange {
    border-top-color: #ffa66f;
    border-left-color: #ffa66f;
    background: #ff6f00;
    border-bottom-color: #894701;
    border-right-color: #894701;
}

button.inactive, .tab.inactive {
    filter: brightness(0.7);
}

button:hover, .tab:hover {
    filter: brightness(1.0);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.tab {
    margin-right: .25em;
    cursor: pointer;
    display: inline-block;
}

#container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: 1600px;
    height: 100%;
    max-height: 1100px;
    flex-grow: 1;

    padding: 1vw 1vh;
}

@media (min-width: 2100px) {
    #container {
        max-width: 90%;
        max-height: 90%;

        width: max(80vw, calc(80vh * 16/11));
        height: max(calc(80vw * 11/16), 80vh);
    }
}

nav {
    margin-bottom: -.6em;
    z-index: 1;
    width: 100%;
    max-width: 1600px;
}

@media (min-width: 600px) {
    nav {
        margin-left: 48px;
    }
}

main {
    flex: 1;
    width: 100%;
    max-width: 1600px;
    max-height: calc(100vh - 100px);

    background-repeat: repeat;
    background-size: 48px;
    background-position: center;

    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: optimizespeed;
    image-rendering: crisp-edges;
    image-rendering: pixelated;

    border-radius: 1rem;

    padding: 1em;
    /*padding-bottom: 2.5em;*/

    overflow-y: auto;

    display: flex;
    flex-direction: column;

    transition: 600ms transform ease-in-out;

    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.3);
}

@media (max-width: 800px) {
    main {
        padding-left: .2em;
        padding-right: .2em;
        padding-top: .2em;
    }
}

main > :last-child {
    padding-bottom: 1.5em;
}

main.red {
    background-color: #d29090;
    /*background-image: url(bg/red-checker.png);*/
}

main.yellow {
    background-color: #e2d8b3;
    background-image: url(bg/yellow-checker.png);
}

main.teal {
    background-color: #cad9d6;
    background-image: url(bg/teal-checker.png);
}

main.green {
    background-color: #68ff51;
    background-image: url(bg/green-checker.png);
}

main.blue {
    background-color: #518bff;
    background-image: url(bg/teal-checker.png);
}

main.orange {
    background-color: #ffd651;
    background-image: url(bg/yellow-checker.png);
}

main > * {
    image-rendering: initial;
}

.shadow-box {
    background: #ffffff44;
    backdrop-filter: blur(4px);
    /*
    background-image: url(bg/yellow-checker.png);
    background-repeat: repeat;
    background-size: 48px;
    background-position: 18px 8px;
    image-rendering: pixelated;
    */

    padding: 1em;
    margin: .5em;
    margin-bottom: 1em;

    border-radius: 16px;
    box-shadow: .4em .4em rgba(0, 0, 0, 0.15);

    border-top: 4px solid #ffffffaa;
    padding-top: calc(1em - 2px);

    border-left: 4px solid #ffffffaa;
    border-right: 4px solid #00000055;
    border-bottom: 4px solid #00000055;

    position: relative;
}

@media (max-width: 800px) {
    .shadow-box {
        padding: .5em;
        margin: .3em;
        margin-bottom: 1em;
    }
}

.shadow-box-inner {
    border-radius: 12px;
    box-shadow: inset .4em .4em rgba(0, 0, 0, 0.15);

    border-top: 4px solid #00000055;
    border-left: 4px solid #00000055;
    border-bottom: 4px solid #ffffffaa;
    border-right: 4px solid #ffffffaa;

    width: 100%;
    height: 100%;

    display: flex;
    position: relative;
    overflow: hidden;

    background: white;
}

button.shadow-box-title {
    position: absolute;
    left: 50%;
    bottom: -1em;
    transform: translateX(-50%);

    width: 90%;
    max-width: 600px;

    text-align: center;

    border-radius: 1em;

    padding-top: .2em;
    height: 1.2em;

    cursor: default;
}

.caption {
    background-image: url(bg/caption-checker.png);
    background-repeat: repeat;
    background-size: 48px;
    background-position: center;

    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: optimizespeed;
    image-rendering: crisp-edges;
    image-rendering: pixelated;

    border-radius: 1rem;
    width: 85%;
    min-height: 4rem;

    padding: .5em 1em;
    margin-top: -2em;
    z-index: 1;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.progress-chart {
    flex: 1;

    font-size: 14px;
    overflow: hidden;

    user-select: none;
    -webkit-user-select: none;
}

.progress-percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: max(48px, 10vw);
    color: rgba(0, 0, 0, 0.15);

    pointer-events: none;
}

.outline-invert {
    color: var(--dark);
    --text-outline: var(--light);
}

.no-outline {
    --text-outline: transparent;
}

.thin {
    font-weight: 400;
    text-shadow: var(--text-outline) 2px 0px 0px, var(--text-outline) 1.75517px 0.958851px 0px, var(--text-outline) 1.0806px 1.68294px 0px, var(--text-outline) 0.141474px 1.99499px 0px, var(--text-outline) -0.832294px 1.81859px 0px, var(--text-outline) -1.60229px 1.19694px 0px, var(--text-outline) -1.97998px 0.28224px 0px, var(--text-outline) -1.87291px -0.701566px 0px, var(--text-outline) -1.30729px -1.5136px 0px, var(--text-outline) -0.421592px -1.95506px 0px, var(--text-outline) 0.567324px -1.91785px 0px, var(--text-outline) 1.41734px -1.41108px 0px, var(--text-outline) 1.92034px -0.558831px 0px;
}

.flex-row { display: flex; flex-direction: row }
.flex-grow { flex: 1 }
.flex-spacer { width: 1em; height: 1em }

.align-right { text-align: right }

.prose {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #000000aa;
    font-size: 0.8rem;
    font-weight: 400;
    --text-outline: transparent;
    text-align: left;
    max-height: 1100px;
    max-width: 60ch;
    margin: auto;
    padding: 1em;
}

.prose a:any-link {
    color: #f9e3e3;
    --text-outline: transparent;
}

.prose a:any-link:hover {
    text-decoration: underline;
}

.prose p {
    margin-top: .2em;
}

.prose h3 {
    margin-bottom: 0;
}

@media screen and (min-width: 100em) {
    .prose {
        display: flex;
        justify-content: space-around;
        max-width: 100%;
        gap: 2em;
    }

    .prose-col {
        width: 60em;
        flex-grow: 0;
    }
}

.aria-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

#banner {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
    min-height: 6em;

    color: white;
    background-color: #518bff;
    background-image: url(bg/red-checker.png);

    padding: 0.5em;

    display: flex;
    align-items: center;
    justify-content: space-around;
}

#close-banner:hover {
    cursor: pointer;
}

/* hide mobile */
@media (max-width: 800px) {
    #banner {
        display: none;
    }
}
